<template>
    <div class="order-table">
        <div class="row mt-3">
            <div class="col-lg-12">
                <div class="">
                    <h3 class="mb-1">MY ORDERS</h3>
                    <div class="order-table-wrap py-2">
                        <div class="row g-3">
                            <template v-for="(order, orIndex) in paginatedOrders">
                                <div @click="$router.push({path: `/orders/${order.id}`})" :key="orIndex" class="col-12 col-md-6 col-lg-12">
                                    <div class="ord-tb-tr p-4 p-lg-5">
                                        <div class="row align-items-lg-center gy-1">
                                            <div class="col-lg-3">
                                                <div class="ord-tb-td" v-if="order.product">
                                                    <div class="mb-2">Number: <strong>#{{order.number}}</strong></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="ord-tb-td">
                                                    <span class="mr-1">Project:</span> <strong>{{order.product.name}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                <div class="ord-tb-td">
                                                    <span class="mr-1">Price:</span> <strong>${{order.amount}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                <div class="ord-tb-td">
                                                    <span class="mr-1">Status:</span> <strong class="text-uppercase">{{order.status}}</strong>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                <div class="ord-tb-td text-lg-right">
                                                    <span class="btn btn-sm btn-outline-success">view</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <p class="text-center bg-white py-2" v-if="!paginatedOrders.length">No order to show</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <b-pagination
                v-model="paginateOptions.currentPage"
                :total-rows="rows"
                :per-page="paginateOptions.perPage"
                @input="onPageChange"
                class="mt-4"
                >
                </b-pagination>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'order-table',
    data() {
        return {
            paginateOptions:{
                perPage: 4,
                currentPage: 1,
                startIndex: 0,
                endIndex: 4
            },
        };
    },
    computed:{
        orders(){
            return this.$store.state.orderList.orders
        },
        rows() {
            return this.orders.length
        },
        paginatedOrders(){
            return this.orders.slice(
                this.paginateOptions.startIndex,
                this.paginateOptions.endIndex
            );
        },
    },
    methods:{
        onPageChange() {
            this.paginateOptions.startIndex = (this.paginateOptions.currentPage - 1) * this.paginateOptions.perPage;
            this.paginateOptions.endIndex = (this.paginateOptions.currentPage - 1) * this.paginateOptions.perPage + this.paginateOptions.perPage;
        },
    },

}
</script>

<style scoped>

.order-table .order-table-wrap .ord-tb-tr{
    background: #fff;
}
.order-table .order-table-wrap .ord-tb-tr:hover{
    background: #f1f5f7;
    transform: all .5s ease-in-out;
    cursor: pointer;
}

</style>
